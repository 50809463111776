//import logo from "./logo.svg";
import "./styles/App.css";
import { useState } from "react";
import { charContext } from "./context/Char.js";
import { mapContext } from "./context/Map.js";
import { cDataContext } from "./context/CData.js";
import { yUrlContext } from "./context/YUrl.js";
import { rotateContext } from "./context/Rotate";

import Selector from "./components/Select.js";
import Svg from "./components/Svg.js";
import Youtube from "./components/Youtube";

function Home() {
  const [maps, setMaps] = useState("ASCENT");
  const [chars, setChars] = useState("VIPER");
  const [cData, setCData] = useState();
  const [yUrl, setYUrl] = useState("https://www.youtube.com/embed/-sASlci7x1c");
  const [rotate, setRotate] = useState(0);

  return (
    <mapContext.Provider value={{ maps, setMaps }}>
      <charContext.Provider value={{ chars, setChars }}>
        <cDataContext.Provider value={{ cData, setCData }}>
          <rotateContext.Provider value={{ rotate, setRotate }}>
            <div id="full_content">
              <header>
                <div id="title">GAK.DOG</div>
                <div id="select">
                  <Selector />
                </div>
              </header>
              <yUrlContext.Provider value={{ yUrl, setYUrl }}>
                <main>
                  <div className="content">
                    <Svg />
                    <Youtube />
                  </div>
                </main>
              </yUrlContext.Provider>
              <hr />
              <footer>
                GAK.DOG isn't endorsed by Riot Games and doesn't reflect the
                views or opinions of Riot Games or anyone officially involved in
                producing or managing Riot Games properties.
                <br /> Riot Games, and all associated properties are trademarks
                or registered trademarks of Riot Games, Inc.
              </footer>
            </div>
          </rotateContext.Provider>
        </cDataContext.Provider>
      </charContext.Provider>
    </mapContext.Provider>
  );
}

export default Home;
