function SvgText({ x, y, rotate, content, className }) {
  let crotate;
  if (rotate === 90) {
    crotate = "-90";
  } else if (rotate === 180) {
    crotate = "-180";
  } else if (rotate === 270) {
    crotate = "90";
  } else if (rotate === 0) {
    crotate = "0";
  }
  return (
    <text
      x={x}
      y={y}
      className={className}
      style={{ transform: `rotate(${crotate}deg)` }}
    >
      {content}
    </text>
  );
}

export default SvgText;
