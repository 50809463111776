const HAVEN = [
  {
    name: "VIPER",
    url: "https://www.youtube.com/embed/pLJtPkzR0vQ",
    location: [
      {
        id: 95,
        time: 1826,
        desc: "헤이븐 A 수레 - A",
        x1: 661,
        y1: 330,
        x2: 420,
        y2: 174,
      },
      {
        id: 96,
        time: 1841,
        desc: "헤이븐 미드 1 - A",
        x1: 658,
        y1: 559,
        x2: 420,
        y2: 174,
      },
      {
        id: 97,
        time: 1860,
        desc: "헤이븐 C 상자 - A",
        x1: 414,
        y1: 928,
        x2: 420,
        y2: 174,
      },
      {
        id: 98,
        time: 1873,
        desc: "헤이븐 B - A",
        x1: 402,
        y1: 576,
        x2: 420,
        y2: 174,
      },
      {
        id: 99,
        time: 1885,
        desc: "헤이븐 A 연결부 - A",
        x1: 382,
        y1: 405,
        x2: 420,
        y2: 174,
      },
      {
        id: 100,
        time: 1930,
        desc: "헤이븐 A - B",
        x1: 385,
        y1: 154,
        x2: 440,
        y2: 470,
      },
      {
        id: 101,
        time: 1948,
        desc: "헤이븐 수비 진형 1 - B",
        x1: 76,
        y1: 359,
        x2: 456,
        y2: 516,
      },
      {
        id: 102,
        time: 1968,
        desc: "102.헤이븐 공격 진형 - B",
        x1: 972,
        y1: 498,
        x2: 456,
        y2: 516,
      },
      {
        id: 103,
        time: 1981,
        desc: "헤이븐 수비 진형 2 - B",
        x1: 150,
        y1: 485,
        x2: 399,
        y2: 554,
      },
      {
        id: 104,
        time: 2062,
        desc: "헤이븐 C 로비 1 - C 메인 설",
        x1: 773,
        y1: 721,
        x2: 419,
        y2: 875,
      },
      {
        id: 105,
        time: 2079,
        desc: "헤이븐 C 로비 2 - C 메인 설",
        x1: 771,
        y1: 732,
        x2: 344,
        y2: 865,
      },
      {
        id: 106,
        time: 2094,
        desc: "헤이븐 로비 3 - C 상자 설",
        x1: 811,
        y1: 790,
        x2: 423,
        y2: 820,
      },
      {
        id: 107,
        time: 2107,
        desc: "헤이븐 미드 1 - C 상자 설",
        x1: 639,
        y1: 666,
        x2: 423,
        y2: 820,
      },
      {
        id: 108,
        time: 2123,
        desc: "헤이븐 미드 2 - C 상자 설",
        x1: 655,
        y1: 571,
        x2: 423,
        y2: 820,
      },
      {
        id: 109,
        time: 2137,
        desc: "헤이븐 C 연결부 1 - C 상자 설",
        x1: 358,
        y1: 613,
        x2: 423,
        y2: 820,
      },
      {
        id: 110,
        time: 2152,
        desc: "헤이븐 C 연결부 2 - C 상자 설",
        x1: 385,
        y1: 609,
        x2: 423,
        y2: 820,
      },
    ],
  },
];

export default HAVEN;
