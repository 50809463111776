const BIND = [
  {
    name: "VIPER",
    url: "https://www.youtube.com/embed/pLJtPkzR0vQ",
    location: [
      {
        id: 42,
        time: 871,
        desc: "바인드 A 메인 1 - 트럭",
        x1: 705,
        y1: 520,
        x2: 709,
        y2: 369,
      },
      {
        id: 43,
        time: 885,
        desc: "바인드 A 메인 2 - 트리플",
        x1: 711,
        y1: 520,
        x2: 780,
        y2: 353,
      },
      {
        id: 44,
        time: 900,
        desc: "바인드 A 공격 진형 1 - 트럭",
        x1: 768,
        y1: 682,
        x2: 709,
        y2: 369,
      },
      {
        id: 45,
        time: 911,
        desc: "바인드 A 공격 진형 2 - 트리플",
        x1: 775,
        y1: 682,
        x2: 780,
        y2: 353,
      },
      {
        id: 46,
        time: 925,
        desc: "바인드 A 텔레포트 앞 1 - 트럭",
        x1: 868,
        y1: 620,
        x2: 709,
        y2: 369,
      },
      {
        id: 47,
        time: 940,
        desc: "바인드 A 공격 진형 2 - 트리플",
        x1: 860,
        y1: 619,
        x2: 780,
        y2: 353,
      },
      {
        id: 48,
        time: 954,
        desc: "바인드 B 후카 앞 1 - 트럭",
        x1: 398,
        y1: 434,
        x2: 709,
        y2: 369,
      },
      {
        id: 49,
        time: 969,
        desc: "바인드 B 입구 - 트럭",
        x1: 209,
        y1: 386,
        x2: 709,
        y2: 369,
      },
      {
        id: 50,
        time: 984,
        desc: "바인드 B 수비 진형 1 - 트럭",
        x1: 388,
        y1: 202,
        x2: 709,
        y2: 369,
      },
      {
        id: 51,
        time: 994,
        desc: "바인드 B 수비 진형 2 - 트럭",
        x1: 478,
        y1: 120,
        x2: 709,
        y2: 369,
      },
      {
        id: 52,
        time: 1012,
        desc: "바인드 B 수비 진형 3 - 트럭",
        x1: 588,
        y1: 129,
        x2: 709,
        y2: 369,
      },
      {
        id: 53,
        time: 1034,
        desc: "바인드 B 연결부 - 트럭",
        x1: 387,
        y1: 596,
        x2: 709,
        y2: 369,
      },
      {
        id: 54,
        time: 1049,
        desc: "바인드 A 연결부 - 트럭",
        x1: 521,
        y1: 612,
        x2: 709,
        y2: 369,
      },
      {
        id: 55,
        time: 1066,
        desc: "바인드 B 후카 앞 2 - B 국민 설",
        x1: 433,
        y1: 494,
        x2: 260,
        y2: 321,
      },
      {
        id: 56,
        time: 1082,
        desc: "바인드 B 분수대 - B 국민 설",
        x1: 236,
        y1: 645,
        x2: 260,
        y2: 321,
      },
      {
        id: 57,
        time: 1100,
        desc: "바인드 B ㄱ자 - B 국민 설",
        x1: 152,
        y1: 303,
        x2: 279,
        y2: 303,
      },
      {
        id: 58,
        time: 1119,
        desc: "바인드 A 텔레포트 앞 3 - B 국민 설",
        x1: 864,
        y1: 627,
        x2: 260,
        y2: 321,
      },
      {
        id: 59,
        time: 1134,
        desc: "바인드 수비 진형 2 - B 국민 설",
        x1: 724,
        y1: 543,
        x2: 279,
        y2: 303,
      },
      {
        id: 60,
        time: 1150,
        desc: "바인드 A 숏 - B 국민 설",
        x1: 726,
        y1: 253,
        x2: 279,
        y2: 303,
      },
      {
        id: 61,
        time: 1167,
        desc: "바인드 A 입구 - B 국민 설",
        x1: 605,
        y1: 199,
        x2: 260,
        y2: 321,
      },
      {
        id: 62,
        time: 1181,
        desc: "바인드 수비 진형 1 - B 국민 설",
        x1: 586,
        y1: 109,
        x2: 279,
        y2: 303,
      },
      {
        id: 63,
        time: 1198,
        desc: "바인드 수비 진형 2 - B 국민 설",
        x1: 502,
        y1: 51,
        x2: 279,
        y2: 303,
      },
    ],
  },
];

export default BIND;
