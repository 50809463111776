const ICEBOX = [
  {
    name: "VIPER",
    url: "https://www.youtube.com/embed/pLJtPkzR0vQ",
    location: [
      {
        id: 81,
        time: 1515,
        desc: "아이스박스 차고 1 - B 중간 설",
        x1: 374,
        y1: 359,
        x2: 631,
        y2: 159,
      },
      {
        id: 82,
        time: 1536,
        desc: "아이스박스 차고 2 - B 구석 설",
        x1: 352,
        y1: 462,
        x2: 572,
        y2: 175,
      },
      {
        id: 83,
        time: 1556,
        desc: "아이스박스 미드 1 - B 구석 설",
        x1: 402,
        y1: 641,
        x2: 572,
        y2: 175,
      },
      {
        id: 84,
        time: 1573,
        desc: "아이스박스 하이미드 1  - B 구석 설",
        x1: 609,
        y1: 648,
        x2: 572,
        y2: 175,
      },
      {
        id: 85,
        time: 1593,
        desc: "아이스박스 눈사람 - B 중간 설",
        x1: 826,
        y1: 249,
        x2: 631,
        y2: 159,
      },
      {
        id: 86,
        time: 1641,
        desc: "아이스박스 공격 진형 - B 컨테이너 설",
        x1: 384,
        y1: 641,
        x2: 626,
        y2: 261,
      },
      {
        id: 87,
        time: 1656,
        desc: "아이스박스 공격 진형 - B 컨테이너 설",
        x1: 249,
        y1: 804,
        x2: 626,
        y2: 261,
      },
      {
        id: 88,
        time: 1670,
        desc: "아이스박스 하이미드 2 - B 컨테이너 설",
        x1: 736,
        y1: 563,
        x2: 626,
        y2: 261,
      },
      {
        id: 89,
        time: 1692,
        desc: "아이스박스 오두막 1- B 컨테이너 설",
        x1: 813,
        y1: 392,
        x2: 668,
        y2: 239,
      },
      {
        id: 90,
        time: 1707,
        desc: "아이스박스 오두막 2- B 컨테이너 설",
        x1: 815,
        y1: 361,
        x2: 668,
        y2: 239,
      },
      {
        id: 91,
        time: 1770,
        desc: "아이스박스 A 입구 1 - A 2층 설",
        x1: 386,
        y1: 889,
        x2: 724,
        y2: 828,
      },
      {
        id: 92,
        time: 1782,
        desc: "아이스박스 A 입구 2 - A 배터리, 상자 설치",
        x1: 381,
        y1: 860,
        x2: 696,
        y2: 864,
      },
      {
        id: 93,
        time: 1798,
        desc: "아이스박스 A 입구 3 - A 배터리 설",
        x1: 463,
        y1: 686,
        x2: 698,
        y2: 889,
      },
      {
        id: 94,
        time: 1810,
        desc: "아이스박스 A 입구 4 - A 배터리 설",
        x1: 494,
        y1: 694,
        x2: 698,
        y2: 889,
      },
    ],
  },
];

export default ICEBOX;
