const PEARL = [
  {
    name: "VIPER",
    url: "https://www.youtube.com/embed/xxx1YBxq0OA",
    location: [
      {
        id: 7,
        time: undefined,
        desc: "펄 A 국민 설치",
        x1: 572,
        y1: 578,
        x2: 855,
        y2: 344,
      },
      {
        id: 8,
        time: undefined,
        desc: "펄 A 구석 설치",
        x1: 583,
        y1: 575,
        x2: 915,
        y2: 345,
      },
      {
        id: 9,
        time: undefined,
        desc: "펄 B 메인 설치",
        x1: 434,
        y1: 710,
        x2: 145,
        y2: 355,
      },
      {
        id: 10,
        time: undefined,
        desc: "펄 B 입구 설치",
        x1: 423,
        y1: 710,
        x2: 130,
        y2: 424,
      },
      {
        id: 11,
        time: undefined,
        desc: "펄 B 입구 설치",
        x1: 289,
        y1: 510,
        x2: 160,
        y2: 449,
      },
    ],
  },
];

export default PEARL;
