import SPLIT from "../data/SPLIT.js";
import ASCENT from "../data/ASCENT.js";
import BIND from "../data/BIND.js";
import FRACTURE from "../data/FRACTURE.js";
import BREEZE from "../data/BREEZE.js";
import PEARL from "../data/PEARL.js";
import HAVEN from "../data/HAVEN.js";

import Lineup from "./Lineup.js";

import { useContext } from "react";

import { mapContext } from "../context/Map.js";
import { rotateContext } from "../context/Rotate.js";
import ICEBOX from "../data/ICEBOX.js";

const mapArr = [ASCENT, SPLIT, BIND, FRACTURE, BREEZE, HAVEN, PEARL, ICEBOX];
const mapIndex = [
  "ASCENT",
  "SPLIT",
  "BIND",
  "FRACTURE",
  "BREEZE",
  "HAVEN",
  "PEARL",
  "ICEBOX",
];

function Svg() {
  const { rotate } = useContext(rotateContext);
  const { maps } = useContext(mapContext);

  let currentData;
  if (mapIndex.indexOf(maps) > -1) {
    currentData = mapArr[mapIndex.indexOf(maps)];
  }

  const mapStyle = {
    transform: `rotate(${rotate}deg)`,
  };

  let imgHref = `/image/${maps}.png`;
  return (
    <div id="map" style={mapStyle}>
      <svg className="mapimg">
        <image className="mapimg" href={imgHref} />
        <Lineup info={currentData} />
      </svg>
    </div>
  );
}

export default Svg;
