const SPLIT = [
  {
    name: "VIPER",
    url: "https://www.youtube.com/embed/pLJtPkzR0vQ",
    location: [
      {
        id: 25,
        time: 501,
        desc: "스플릿 B 메인 1 - 국민 설",
        x1: 702,
        y1: 840,
        x2: 354,
        y2: 890,
      },
      {
        id: 26,
        time: 523,
        desc: "스플릿 B 메인 2 - 국민 설",
        x1: 706,
        y1: 772,
        x2: 338,
        y2: 873,
      },
      {
        id: 27,
        time: 536,
        desc: "스플릿 B 헤븐 - 국민 설",
        x1: 498,
        y1: 639,
        x2: 323,
        y2: 887,
      },
      {
        id: 28,
        time: 549,
        desc: "스플릿 B 골목길 - 국민 설",
        x1: 242,
        y1: 722,
        x2: 349,
        y2: 887,
      },
      {
        id: 29,
        time: 579,
        desc: "스플릿 B 메인 2 - 국민 설",
        x1: 101,
        y1: 525,
        x2: 354,
        y2: 903,
      },
      {
        id: 30,
        time: 536,
        desc: "스플릿 B 메인 2 - 국민 설",
        x1: 739,
        y1: 373,
        x2: 300,
        y2: 150,
      },
      {
        id: 31,
        time: 536,
        desc: "스플릿 B 메인 2 - 국민 설",
        x1: 690,
        y1: 161,
        x2: 299,
        y2: 151,
      },
      {
        id: 32,
        time: 536,
        desc: "스플릿 B 메인 2 - 국민 설",
        x1: 678,
        y1: 134,
        x2: 300,
        y2: 114,
      },
      {
        id: 33,
        time: 536,
        desc: "스플릿 B 메인 2 - 국민 설",
        x1: 637,
        y1: 104,
        x2: 349,
        y2: 76,
      },
      {
        id: 34,
        time: 536,
        desc: "스플릿 B 메인 2 - 국민 설",
        x1: 640,
        y1: 91,
        x2: 406,
        y2: 90,
      },
      {
        id: 35,
        time: 536,
        desc: "스플릿 B 메인 2 - 국민 설",
        x1: 147,
        y1: 339,
        x2: 238,
        y2: 110,
      },
      {
        id: 36,
        time: 536,
        desc: "스플릿 B 메인 2 - 국민 설",
        x1: 140,
        y1: 347,
        x2: 272,
        y2: 116,
      },
    ],
  },
  {
    name: "BRIMSTONE",
    url: "https://www.youtube.com/embed/-sASlci7x1c",
    location: [
      {
        id: 25,
        time: 501,
        x1: 1,
        y1: 900,
        x2: 900,
        y2: 1,
      },
      {
        id: 26,
        time: 523,
        x1: 1,
        y1: 1,
        x2: 900,
        y2: 900,
      },
    ],
  },
];

export default SPLIT;
