const ASCENT = [
  {
    name: "VIPER",
    url: "https://www.youtube.com/embed/pLJtPkzR0vQ",
    location: [
      {
        id: 1,
        time: 0,
        desc: "어센트 A 와인방 1 - 배터리",
        x1: 503,
        y1: 68,
        x2: 355,
        y2: 192,
      },
      {
        id: 2,
        time: 24,
        desc: "어센트 A 와인방 2 - 상자 설치",
        x1: 504,
        y1: 84,
        x2: 320,
        y2: 128,
      },
      {
        id: 3,
        time: 37,
        desc: "어센트 A 메인 1 - 상자 설치",
        x1: 575,
        y1: 299,
        x2: 346,
        y2: 129,
      },
      {
        id: 4,
        time: 48,
        desc: "어센트 A 메인 2 - 배터리",
        x1: 610,
        y1: 271,
        x2: 355,
        y2: 192,
      },
      {
        id: 5,
        time: 66,
        desc: "어센트 A 메인 3 - 상자 설치",
        x1: 630,
        y1: 250,
        x2: 346,
        y2: 129,
      },
      {
        id: 6,
        time: 83,
        desc: "어센트 하이미드 1 - 상자 설치",
        x1: 676,
        y1: 460,
        x2: 346,
        y2: 129,
      },
      {
        id: 7,
        time: 100,
        desc: "어센트 하이미드 2 - 배터리",
        x1: 697,
        y1: 463,
        x2: 355,
        y2: 192,
      },
      {
        id: 8,
        time: 117,
        desc: "어센트 미드 1 - 배터리",
        x1: 436,
        y1: 557,
        x2: 355,
        y2: 192,
      },
      {
        id: 9,
        time: 135,
        desc: "어센트 미드 2 - 상자",
        x1: 435,
        y1: 557,
        x2: 346,
        y2: 129,
      },
      {
        id: 10,
        time: 151,
        desc: "어센트 미드 3 - 상자",
        x1: 214,
        y1: 505,
        x2: 346,
        y2: 129,
      },
      {
        id: 11,
        time: 167,
        desc: "어센트 미드 4 - 상자",
        x1: 146,
        y1: 365,
        x2: 346,
        y2: 129,
      },
      {
        id: 12,
        time: 183,
        desc: "어센트 A 헤븐 - 배터리",
        x1: 151,
        y1: 242,
        x2: 355,
        y2: 192,
      },
      {
        id: 13,
        time: 198,
        desc: "어센트 A 나무 1 - 상자",
        x1: 303,
        y1: 371,
        x2: 320,
        y2: 128,
      },
      {
        id: 14,
        time: 223,
        desc: "어센트 A 나무 2 - 배터리",
        x1: 296,
        y1: 286,
        x2: 355,
        y2: 192,
      },
      {
        id: 15,
        time: 234,
        desc: "어센트 A 나무 3 - 배터리",
        x1: 404,
        y1: 354,
        x2: 355,
        y2: 192,
      },
      {
        id: 16,
        time: 312,
        desc: "어센트 B 메인 1 - 상자",
        x1: 592,
        y1: 789,
        x2: 291,
        y2: 837,
      },
      {
        id: 17,
        time: 327,
        desc: "어센트 B 메인 2 - 입구 설",
        x1: 541,
        y1: 785,
        x2: 315,
        y2: 779,
      },
      {
        id: 18,
        time: 343,
        desc: "어센트 B 메인 3 - 상자",
        x1: 487,
        y1: 757,
        x2: 291,
        y2: 837,
      },
      {
        id: 19,
        time: 359,
        desc: "어센트 B 메인 4 - 입구 설",
        x1: 406,
        y1: 770,
        x2: 355,
        y2: 769,
      },
      {
        id: 20,
        time: 370,
        desc: "어센트 피자 1 - 상자",
        x1: 332,
        y1: 453,
        x2: 291,
        y2: 837,
      },
      {
        id: 21,
        time: 370,
        desc: "어센트 피자 1 - 입구 설",
        x1: 324,
        y1: 452,
        x2: 316,
        y2: 778,
      },
      {
        id: 22,
        time: 400,
        desc: "어센트 수비 진형 1 - 상자",
        x1: 51,
        y1: 498,
        x2: 291,
        y2: 837,
      },
      {
        id: 23,
        time: 436,
        desc: "어센트 수비 진형 2 - 입구 설",
        x1: 52,
        y1: 507,
        x2: 340,
        y2: 779,
      },
    ],
  },
  {
    name: "BRIMSTONE",
    url: "https://www.youtube.com/embed/oLVgwsevkco",
    location: [
      {
        id: 25,
        time: 501,
        x1: 1,
        y1: 900,
        x2: 900,
        y2: 1,
      },
      {
        id: 26,
        time: 523,
        x1: 1,
        y1: 1,
        x2: 900,
        y2: 900,
      },
    ],
  },
];

export default ASCENT;
