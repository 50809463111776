const FRACTURE = [
  {
    name: "VIPER",
    url: "https://www.youtube.com/embed/pLJtPkzR0vQ",
    location: [
      {
        id: 37,
        time: 783,
        desc: "프랙처 B 메인 - 상자",
        x1: 220,
        y1: 822,
        x2: 159,
        y2: 544,
      },
      {
        id: 38,
        time: 799,
        desc: "프랙처 B 아케이드 1 - 상자",
        x1: 205,
        y1: 321,
        x2: 159,
        y2: 544,
      },
      {
        id: 39,
        time: 816,
        desc: "프랙처 B 아케이드 2 - 상자",
        x1: 381,
        y1: 244,
        x2: 159,
        y2: 544,
      },
      {
        id: 40,
        time: 835,
        desc: "프랙처 A 메인 - 입구 설",
        x1: 753,
        y1: 817,
        x2: 861,
        y2: 558,
      },
      {
        id: 41,
        time: 854,
        desc: "프랙처 A 안테나 - 입구 설",
        x1: 878,
        y1: 275,
        x2: 861,
        y2: 558,
      },
    ],
  },
];

export default FRACTURE;
