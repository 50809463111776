import { useContext, useEffect, useState } from "react";

import { charContext } from "../context/Char.js";
import { mapContext } from "../context/Map.js";
import { cDataContext } from "../context/CData.js";
import { yUrlContext } from "../context/YUrl.js";
import { rotateContext } from "../context/Rotate.js";

import DrawCircle from "./DrawCircle.js";
import DrawLine from "./DrawLine.js";
import SvgText from "./SvgText.js";
import "../styles/lineup.css";

//const SVG_KEY = "http://www.w3.org/2000/svg";

function Lineup({ info }) {
  const { rotate } = useContext(rotateContext);
  const { chars } = useContext(charContext);
  const { maps } = useContext(mapContext);
  const { cData, setCData } = useContext(cDataContext);
  const { setYUrl } = useContext(yUrlContext);
  const [mNumber, setmNumber] = useState(0.75);
  let index;

  useEffect(() => {
    if (window.matchMedia("screen and (max-width: 768px)").matches === true) {
      setmNumber(0.3);
    } else if (
      window.matchMedia("screen and (max-width: 768px)").matches === false
    ) {
      setmNumber(0.75);
    }
    if (info !== undefined) {
      for (let i = 0; i < info.length; i++) {
        if (info[i].name === chars) {
          index = i;
        }
      }
      setCData(info[index].location);
      setYUrl(info[index].url);
    } else {
      setCData();
      setYUrl();
    }
  }, [maps, chars, rotate]);

  return (
    <g>
      {cData !== undefined ? (
        <g>
          {cData.map((infos) => (
            <g key={infos.id}>
              <DrawLine
                x1={infos.x1 * mNumber}
                y1={infos.y1 * mNumber}
                x2={infos.x2 * mNumber}
                y2={infos.y2 * mNumber}
              />
              <DrawCircle
                x2={infos.x2 * mNumber}
                y2={infos.y2 * mNumber}
                name={chars}
              />
              <SvgText
                className="charText"
                x={(infos.x1 - 7) * mNumber}
                y={(infos.y1 + 7) * mNumber}
                content={infos.id}
                rotate={rotate}
              ></SvgText>
              <circle
                className="lineupDot"
                cx={infos.x2 * mNumber}
                cy={infos.y2 * mNumber}
              ></circle>
            </g>
          ))}
        </g>
      ) : null}
    </g>
  );
}

export default Lineup;
