import { useContext } from "react";
import { charContext } from "../context/Char.js";
import { mapContext } from "../context/Map.js";

import RotateBtn from "./RotateBtn";

function Selector() {
  const { setMaps } = useContext(mapContext);
  const { setChars } = useContext(charContext);
  const mapArr = [
    "ASCENT",
    "BIND",
    "HAVEN",
    "SPLIT",
    "ICEBOX",
    "BREEZE",
    "FRACTURE",
    "PEARL",
  ];
  const charArr = ["VIPER"];

  function onMapChange(m) {
    setMaps(m.target.value);
  }

  function onCharChange(c) {
    setChars(c.target.value);
  }
  return (
    <div id="selectGroup">
      <select onChange={onMapChange}>
        {mapArr.map((map) => (
          <option key={map}>{map}</option>
        ))}
      </select>
      <RotateBtn />

      <select onChange={onCharChange}>
        {charArr.map((char) => (
          <option key={char}>{char}</option>
        ))}
      </select>
    </div>
  );
}

export default Selector;
