import { useContext, useEffect, useState } from "react";
import { cDataContext } from "../context/CData.js";
import { yUrlContext } from "../context/YUrl.js";
import "../styles/youtube.css";

function Youtube() {
  const { yUrl } = useContext(yUrlContext);
  const { cData } = useContext(cDataContext);
  const [cUrl, setCUrl] = useState(yUrl);
  useEffect(() => setCUrl(yUrl), [yUrl]);

  function onClick(event) {
    setCUrl(yUrl + `?start=${event.target.id}` + "&mute=1&autoplay=1");
    console.log(window.matchMedia("screen and (max-width: 768px)"));
    console.log(cUrl);
  }

  return (
    <div>
      <iframe
        id="ytbVideo"
        src={cUrl}
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
      {cData !== undefined ? (
        <div id="btnGroup">
          {cData.map((infos) => (
            <button
              className="ytbBtn"
              key={infos.id}
              onClick={onClick}
              id={infos.time}
            >
              {infos.id + " " + infos.desc}
            </button>
          ))}
        </div>
      ) : null}
    </div>
  );
}

export default Youtube;
