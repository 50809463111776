const BREEZE = [
  {
    name: "VIPER",
    url: "https://www.youtube.com/embed/pLJtPkzR0vQ",
    location: [
      {
        id: 64,
        time: 1241,
        desc: "브리즈 A 마당 1 - A 중간 설",
        x1: 716,
        y1: 873,
        x2: 856,
        y2: 502,
      },
      {
        id: 65,
        time: 1257,
        desc: "브리즈 A 마당 2 - A 안쪽 설",
        x1: 730,
        y1: 871,
        x2: 785,
        y2: 508,
      },
      {
        id: 66,
        time: 1274,
        desc: "브리즈 A 마당 3 - A 강제 설",
        x1: 741,
        y1: 862,
        x2: 938,
        y2: 516,
      },
      {
        id: 67,
        time: 1289,
        desc: "브리즈 공격 진형 1 - A 중간 설",
        x1: 531,
        y1: 764,
        x2: 856,
        y2: 502,
      },
      {
        id: 68,
        time: 1306,
        desc: "브리즈 공격 진형 2 - A 안쪽 설",
        x1: 528,
        y1: 754,
        x2: 785,
        y2: 508,
      },
      {
        id: 69,
        time: 1317,
        desc: "브리즈 공격 진형 3 - A 강제 설",
        x1: 535,
        y1: 756,
        x2: 938,
        y2: 516,
      },
      {
        id: 70,
        time: 1331,
        desc: "브리즈 미드 - A 중간 설",
        x1: 356,
        y1: 453,
        x2: 856,
        y2: 502,
      },
      {
        id: 71,
        time: 1348,
        desc: "브리즈 A 복도 1 - A 중간 설",
        x1: 640,
        y1: 563,
        x2: 856,
        y2: 502,
      },
      {
        id: 72,
        time: 1366,
        desc: "브리즈 A 복도 2 - A 안쪽 설",
        x1: 648,
        y1: 578,
        x2: 785,
        y2: 508,
      },
      {
        id: 73,
        time: 1379,
        desc: "브리즈 A 복도 3 - A 안쪽 설",
        x1: 646,
        y1: 261,
        x2: 785,
        y2: 508,
      },
      {
        id: 74,
        time: 1395,
        desc: "브리즈 수비 진형1 - A 중간 설",
        x1: 553,
        y1: 204,
        x2: 856,
        y2: 502,
      },
      {
        id: 75,
        time: 1413,
        desc: "브리즈 B 메인 - B 메인 설",
        x1: 99,
        y1: 573,
        x2: 115,
        y2: 333,
      },
      {
        id: 76,
        time: 1428,
        desc: "브리즈 B 골목 - B 바깥 설",
        x1: 236,
        y1: 635,
        x2: 192,
        y2: 340,
      },
      {
        id: 77,
        time: 1446,
        desc: "브리즈 B 대포 - B 메인 설",
        x1: 313,
        y1: 599,
        x2: 115,
        y2: 333,
      },
      {
        id: 78,
        time: 1463,
        desc: "브리즈 미드 상자 -  B 메인 설",
        x1: 469,
        y1: 535,
        x2: 115,
        y2: 333,
      },
      {
        id: 79,
        time: 1479,
        desc: "브리즈 수비 진형 2 - B 메인 설",
        x1: 636,
        y1: 208,
        x2: 115,
        y2: 333,
      },
      {
        id: 80,
        time: 1496,
        desc: "브리즈 수비 진형 3 - B 바깥 설",
        x1: 600,
        y1: 204,
        x2: 192,
        y2: 340,
      },
    ],
  },
];

export default BREEZE;
