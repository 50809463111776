import { useContext } from "react";
import { rotateContext } from "../context/Rotate";

function RotateBtn() {
  const { rotate, setRotate } = useContext(rotateContext);
  function rotateChange() {
    if (rotate === 270) {
      setRotate(0);
      return;
    }
    setRotate((prev) => prev + 90);
  }

  return (
    <button id="rotateBtn" onClick={rotateChange}>
      🔄
    </button>
  );
}

export default RotateBtn;
